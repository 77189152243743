import * as React from 'react';

import {
  alpha,
  Box,
  Card,
  Link,
  darken,
  CardMedia,
  Typography,
  // Button,
  styled,
  useTheme,
} from '@mui/material';

// import { Link as RouterLink } from 'react-router-dom';

const BgComposed = styled(Box)(
  ({ theme }) => `
      position: relative;
      z-index: 6;
      background: ${darken(alpha(theme.colors.secondary.main, 0.5), 0.8)};
      box-shadow: inset 0 0 2rem 1rem ${darken(theme.colors.secondary.main, 0.9)};
    `
);

export default function ImageCardButton({
  image,
  onClick
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: '150px',
        position: 'relative',
        transition: `${theme.transitions.create([
          'box-shadow',
          'transform'
        ])}`,
        transform: 'translateY(0px)',

        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: `0 3rem 10rem 0 ${alpha(
            theme.colors.primary.dark,
            0.1
          )}, 
                  0 0 3rem ${alpha(theme.colors.primary.dark, 0.9)}, 
                  0 0.2rem 0.2rem ${alpha(theme.colors.primary.dark, 0.8)}`
        }
      }}
    >
      <Link
        onClick={onClick}
        // href={`/app/pointclouds/${scanworld.id}`}
        underline="none"
        sx={{
          cursor: 'pointer'
        }}
      >
        <BgComposed
          sx={{
            p: { xs: 3, md: 5, lg: 6 }
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent='center'
            sx={{
              p: { xs: 1 }
            }}
          >
            <Box display="flex">
              <Typography
                variant="h6"
                sx={{
                  color: `${theme.colors.alpha.trueWhite[100]}`,
                }}
              >
                {image.name}
              </Typography>
            </Box>
          </Box>
        </BgComposed>
        <CardMedia
          component="img"
          height="100%"
          width="auto"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            borderRadius: 'inherit',
            zIndex: 5
          }}
          image={`${image.path}`}
          alt={`Screenshot of the ${image.name} points cloud`}
        />
      </Link>
    </Card>
  );
}
