import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/common/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Categories = Loader(
  lazy(() => import('../content/Maintenance/Categories'))
);

const Models = Loader(
  lazy(() => import('../content/Maintenance/Models'))
);

const Manufacturers = Loader(
  lazy(() => import('../content/Maintenance/Manufacturers'))
);

const Suppliers = Loader(
  lazy(() => import('../content/Maintenance/Suppliers'))
);
const Locations = Loader(
  lazy(() => import('../content/Maintenance/Locations'))
);

const routes = [
    {
        path: '/maintenance/categories',
        element: <Categories />
    },
    {
        path: '/maintenance/models',
        element: <Models />
    },
    {
        path: '/maintenance/manufacturers',
        element: <Manufacturers />
    },
    {
        path: '/maintenance/suppliers',
        element: <Suppliers />
    },
    {
        path: '/maintenance/locations',
        element: <Locations />
    }
];

export default routes;