import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedRole: null,
    roles: []
};

const slice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        // Set the Select Role that will be viewed on the roles page
        setSelectedRole(state, action) {
            const role = action.payload;

            state.selectedRole = role;
        },
        // Get Activities
        getRoles(state, action) {
            const roles = action.payload;

            state.roles = roles;
        },
        // Add Role
        addRole(state, action) {
            const role = action.payload;

            state.roles = [...state.roles, role];
        },
        // Edit Role
        updateRole(state, action) {
            const {id, data} = action.payload;

            state.roles = _.map(state.roles, (_role) => {
                if (_role.id === id) {
                    _role = { id, ...data }
                }

                return _role;
            })
        },
        // Archive Role
        archiveRole(state, action) {
            const id = action.payload;

            state.roles = _.map(state.roles, (_role) => {
                if (_role.id === id) {
                    _role.inactive = true;
                    _role.inactive_date = new Date();
                }

                return _role;
            })
        },
        // unArchive Role
        unArchiveRole(state, action) {
            const id = action.payload;

            state.roles = _.map(state.roles, (_role) => {
                if (_role.id === id) {
                    _role.inactive = false;
                    _role.inactive_date = undefined;
                }

                return _role;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedRole = (roleID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/roles/${roleID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedRole response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedRole(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all roles
 */
export const getRoles = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/roles`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getRoles response: `), response.data)
                    }

                    dispatch(slice.actions.getRoles(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a role
 */
export const addRole = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/roles`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addRole response: `), response.data)
                    }

                    dispatch(slice.actions.addRole(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a role
 */
export const bookRole = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.post(`/roles/${id}/book`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`bookRole response: `), response.data)
                    }

                    dispatch(slice.actions.bookRole({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a role
 */
export const updateRole = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/roles/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateRole response: `), response.data)
                    }

                    dispatch(slice.actions.updateRole({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a role
 */
export const archiveRole = (roleID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveRole(roleID));

        try {
            axios.delete(`/roles/${roleID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveRole response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a role
 */
export const unArchiveRole = (roleID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveRole(roleID));

        try {
            axios.patch(`/roles/${roleID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveRole response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
