import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedUser: null,
    users: []
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // Set the Select User that will be viewed on the users page
        setSelectedUser(state, action) {
            const user = action.payload;

            state.selectedUser = user;
        },
        // Get Activities
        getUsers(state, action) {
            const users = action.payload;

            state.users = users;
        },
        // Add User
        addUser(state, action) {
            const user = action.payload;

            state.users = [...state.users, user];
        },
        // Edit User
        updateUser(state, action) {
            const {id, data} = action.payload;

            state.users = _.map(state.users, (_user) => {
                if (_user.id === id) {
                    _user = { id, ...data }
                }

                return _user;
            })
        },
        // Archive User
        disableUser(state, action) {
            const id = action.payload;

            state.users = _.map(state.users, (_user) => {
                if (_user.id === id) {
                    _user.inactive = true;
                    _user.inactive_date = new Date();
                }

                return _user;
            })
        },
        // unArchive User
        enableUser(state, action) {
            const id = action.payload;

            state.users = _.map(state.users, (_user) => {
                if (_user.id === id) {
                    _user.inactive = false;
                    _user.inactive_date = undefined;
                }

                return _user;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedUser = (userID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/users/${userID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedUser response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedUser(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all users
 */
export const getUsers = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/users`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getUsers response: `), response.data)
                    }

                    dispatch(slice.actions.getUsers(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets count of users each month between dates
 */
export const getUserCount = async (startDate, endDate) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/users/count`, { params: { startDate, endDate }})
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getUserCount response: `), response.data)
                    }

                    resolve(response.data.result);
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets count of users by location
 */
export const getUsersLocations = async (startDate, endDate) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/users/location`, { params: { startDate, endDate }})
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getUserLocations response: `), response.data)
                    }

                    resolve(response.data.result);
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a user
 */
export const addUser = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/users`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addUser response: `), response.data)
                    }

                    dispatch(slice.actions.addUser(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a user
 */
export const bookUser = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.post(`/users/${id}/book`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`bookUser response: `), response.data)
                    }

                    dispatch(slice.actions.bookUser({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a user
 */
export const updateUser = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/users/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateUser response: `), response.data)
                    }

                    dispatch(slice.actions.updateUser({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a user
 */
export const disableUser = (userID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.disableUser(userID));

        try {
            axios.delete(`/users/${userID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`disableUser response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a user
 */
export const enableUser = (userID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.enableUser(userID));

        try {
            axios.patch(`/users/${userID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`enableUser response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
