import { useEffect, useState } from 'react';
import { Box, alpha, Card, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Card)(
  ({theme}) => `
        border-radius: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        width: 100%;
`
);

function Footer() {

  let [date, setDate] = useState(new Date());

  useEffect(() => {
    setDate(new Date())
  }, [])

  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        px={3}
        py={1}
        display="block"
        textAlign={{ xs: 'center', md: 'right' }}
      >
        <Typography variant="subtitle1">
          &copy; {date.getFullYear()} - Legeneering IT Services
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
