import { createSlice } from '@reduxjs/toolkit';
import chalk from 'chalk';
import axios from 'src/common/utils/axios';

const initialState = {
  preferences:
  {
    general: {},
    notification: {},
    security: {},
  }

};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    /**
     * Getting the general preference states
     * @param {*} state 
     * @param {*} action 
     */
    setGeneralPreferencesState(state, action) {
      const preference = action.payload;

      state.preferences.general = preference;
    },
    /**
     * Setting the notification preference states
     * @param {*} state 
     * @param {*} action 
     */
    setNotificationPreferencesState(state, action) {
      const preference = action.payload;

      state.preferences.notification = preference;
    },
    /**
     * Getting the security preference states
     * @param {*} state 
     * @param {*} action 
     */
    setSecurityPreferencesState(state, action) {
      const preference = action.payload;

      state.preferences.security = preference;
    },

    /**
     * Update weekly report preferences state 
     * @param {*} state 
     * @param {*} action 
     */
    updatePreferredNotificationWeeklyReportState(state, action) {

      const preference = action.payload;

      state.preferences.notification.weekly_report = preference;
    },

    /**
     * Update orders activity report preferences slice
     * @param {*} state 
     * @param {*} action 
     */
    updatePreferredNotificationOrdersActivityState(state, action) {

      const preference = action.payload;

      state.preferences.notification.order_status_update = preference;
    },

    /**
     * Update 2FA preferences slice
     * @param {*} state 
     * @param {*} action 
     */
    updatePreferredTwoFactorAuthState(state, action) {

      const preference = action.payload;

      state.preferences.security.two_factor_auth = preference;
    },

  }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API to get the general preferences and sets the general pref state in Redux
 */
export const getGeneralPreferences = () => async (dispatch) => {

  return new Promise((resolve, reject) => {
    try {

      axios.get(`/profile/preferences/general`)
        .then((response) => {

          if (process.env.NODE_ENV === 'development') {
            console.log(chalk.cyan(`getGeneralPreferences result: ${response.data.result}`))
          }

          dispatch(slice.actions.setNotificationPreferencesState(response.data.result))

          resolve();
        })

    }
    catch (err) {
      reject(err);
    }
  })
}

/**
 * Function that calls the API to get the notification preferences and sets state in Redux
 */
export const getNotificationPreferences = () => async (dispatch) => {

  return new Promise((resolve, reject) => {
    try {

      axios.get(`/profile/preferences/notification`)
        .then((response) => {

          if (process.env.NODE_ENV === 'development') {
            console.log(chalk.cyan(`getNotificationPreferences result: `), response.data.result)
          }

          dispatch(slice.actions.setNotificationPreferencesState(response.data.result))

          resolve();
        })

    }
    catch (err) {
      reject(err);
    }
  })
}

/**
 * Function that calls the API to get the security preferences and sets state in Redux
 */
export const getSecurityPreferences = () => async (dispatch) => {

  return new Promise((resolve, reject) => {
    try {

      axios.get(`/profile/preferences/security`)
        .then((response) => {

          if (process.env.NODE_ENV === 'development') {
            console.log(chalk.cyan(`getSecurityPreferences result: `), response.data.result)
          }

          dispatch(slice.actions.setSecurityPreferencesState(response.data.result))

          resolve();
        })

    }
    catch (err) {
      reject(err);
    }
  })
}

/**
 * Function that calls the API to update the preference on receiving a weekly report (true/false)
 * @param {boolean} preference receive/not receive the weekly report 
 * @returns 
 */
export const updatePreferredWeeklyReport = (preference) => async (dispatch) => {

  return new Promise((resolve, reject) => {
    try {

      axios.patch(`/profile/preferences/notification/weeklyreport`, { weeklyReport: preference })
        .then((response) => {

          dispatch(slice.actions.updatePreferredNotificationWeeklyReportState(preference))

          resolve(response);
        })

    }
    catch (err) {
      reject(err);
    }
  })
}

/**
 * Function that calls the API to update the preference on receiving he orders activity report (true/false)
 * @param {boolean} preference receive/not receive the orders activity report 
 * @returns 
 */
export const updatePreferredOrdersActivity = (preference) => async (dispatch) => {

  return new Promise((resolve, reject) => {
    try {

      axios.patch(`/profile/preferences/notification/ordersactivity`, { ordersActivityReport: preference })
        .then((response) => {

          dispatch(slice.actions.updatePreferredNotificationOrdersActivityState(preference))

          resolve(response);
        })

    }
    catch (err) {
      reject(err);
    }
  })
}

/**
 * Function that calls the API to update the preference on using 2FA (true/false)
 * @param {boolean} preference use/not 2FA 
 * @returns 
 */
export const updatePreferredTwoFactorAuth = (preference) => async (dispatch) => {

  return new Promise((resolve, reject) => {
    try {

      axios.patch(`/profile/preferences/security/2fa`, { twoFactAuth: preference })
        .then((response) => {

          dispatch(slice.actions.updatePreferredTwoFactorAuthState(preference))

          resolve(response);
        })

    }
    catch (err) {
      reject(err);
    }
  })
}