import chalk from 'chalk';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import axios from 'src/common/utils/axios';

const initialState = {
    activeTickets: [],
    resolvedTickets: []
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        
        addSupportTicket(state, action) {
            const supportTicket = action.payload;

            state.activeTickets = [...state.activeTickets, supportTicket]
        },

        getActiveSupportTickets(state, action) {
            const supportTickets = action.payload;

            state.activeTickets = supportTickets;
        },

        getResolvedSupportTickets(state, action) {
            const supportTickets = action.payload;

            state.resolvedTickets = supportTickets;
        },
        
        removeSupportTicket(state, action) {
            const { ticket } = action.payload;

            state.activeTickets = _.reject(state.activeTickets, { id: ticket });
        },
    }
});

export const reducer = slice.reducer;


/**
 * Function that calls the API to create a new support ticket
 * @param {*} data the ticket data
 * @returns the new ticket created
 */
export const addSupportTicket = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(chalk.cyan(`addSupportTicket data: ${data}`))
        }
        try {
            axios.post(`/support/ticket`, data)
                .then(response => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addSupportTicket response: ${response.data.result}`))
                    }
                    dispatch(slice.actions.addSupportTicket(response.data.result));
                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
}

/**
 * Function that calls the API to retrieve all the active support tickets 
 * @returns The list of support tickets
 */
export const getActiveSupportTickets = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {

            axios.get(`/support/tickets/active`)
                .then((response) => {
                    dispatch(slice.actions.getActiveSupportTickets(response.data.result));
                    resolve();
                })

        }
        catch (err) {
            reject(err);
        }
    })
}
/**
 * Function that calls the API to retrieve all the active support tickets 
 * @returns The list of support tickets
 */
export const getResolvedSupportTickets = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {

            axios.get(`/support/tickets/resolved`)
                .then((response) => {
                    dispatch(slice.actions.getResolvedSupportTickets(response.data.result));
                    resolve();
                })

        }
        catch (err) {
            reject(err);
        }
    })
}

/**
 * Function that deletes a support ticket (soft delete)
 * @param {*} ticket The id of the ticket to delete
 * @returns 
 */
export const removeSupportTicket = (ticket) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.delete(`/support/ticket/${ticket}`)
                .then(() => {

                    dispatch(slice.actions.removeSupportTicket({ ticket }));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;