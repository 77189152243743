import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/common/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Users = Loader(
  lazy(() => import('../content/Users'))
);

const Departments = Loader(
  lazy(() => import('../content/Departments'))
);

const Roles = Loader(
  lazy(() => import('../content/Roles'))
);

const SecurityGroups = Loader(
  lazy(() => import('../content/SecurityGroups'))
);

const routes = [
  {
      path: '/users',
      element: <Users />
  },
  {
    path: '/departments',
    element: <Departments />
  },
  {
      path: '/roles',
      element: <Roles />
  },
  {
    path: '/securitygroups',
    element: <SecurityGroups />
  }
];

export default routes;