import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/common/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Assets = Loader(
  lazy(() => import('../content/Assets'))
);

const Accessories = Loader(
  lazy(() => import('../content/Accessories'))
);

const Licenses = Loader(
  lazy(() => import('../content/Licenses'))
);

const Consumables = Loader(
  lazy(() => import('../content/Consumables'))
);

const ServiceContracts = Loader(
  lazy(() => import('../content/ServiceContracts'))
);
const Bookings = Loader(
  lazy(() => import('../content/Bookings'))
);

const routes = [
    {
        path: '/assets',
        element: <Assets />
    },
    {
        path: '/accessories',
        element: <Accessories />
    },
    {
        path: '/licenses',
        element: <Licenses />
    },
    {
        path: '/consumables',
        element: <Consumables />
    },
    {
        path: '/servicecontracts',
        element: <ServiceContracts />
    },
    {
        path: '/bookings',
        element: <Bookings />
    }
];

export default routes;