import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/common/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const LoginCover = Loader(
  lazy(() => import('src/core/content/App/Auth/Login'))
);

const accountRoutes = [
  {
    path: 'login',
    element: <LoginCover />
  }
];

export default accountRoutes;
