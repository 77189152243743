import { combineReducers } from '@reduxjs/toolkit';

import { reducer as profileReducer } from 'src/core/slices/profile'
import { reducer as profilesReducer } from 'src/core/slices/profiles'
import { reducer as invitesReducer } from 'src/core/slices/invites'
import { reducer as supportReducer } from 'src/core/slices/support';

import { reducer as locationsReducer } from 'src/core/slices/locations';
import { reducer as categoriesReducer } from 'src/core/slices/categories';
import { reducer as manufacturersReducer } from 'src/core/slices/manufacturers';
import { reducer as suppliersReducer } from 'src/core/slices/suppliers';
import { reducer as modelsReducer } from 'src/core/slices/models';

import { reducer as usersReducer } from 'src/modules/UserManagement/slices/users'
import { reducer as departmentsReducer } from 'src/modules/UserManagement/slices/departments'
import { reducer as rolesReducer } from 'src/modules/UserManagement/slices/roles'
import { reducer as securityGroupsReducer } from 'src/modules/UserManagement/slices/securitygroups'

import { reducer as assetsReducer } from 'src/modules/EquipmentManagement/slices/assets'

const rootReducer = combineReducers({
  profiles: profilesReducer,
  profile: profileReducer,
  invites: invitesReducer,
  support: supportReducer,
  users: usersReducer,
  departments: departmentsReducer,
  roles: rolesReducer,
  securityGroups: securityGroupsReducer,
  assets: assetsReducer,
  locations: locationsReducer,
  categories: categoriesReducer,
  manufacturers: manufacturersReducer,
  suppliers: suppliersReducer,
  models: modelsReducer
});

export default rootReducer;
