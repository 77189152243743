import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import Footer from 'src/common/components/Footer';

import { Box } from '@mui/material';

const BaseLayout = ({ children }) => {
  return (
    <>
      <Box id="viewer-fullscreen" sx={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', display: 'none', overflow: 'hidden', zIndex: 800 }} />
      <Box
        sx={{
          flex: 1,
          height: '100%'
        }}
      >
        {children || <Outlet />}
      </Box>
      <Footer />
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
