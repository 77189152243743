import { useContext } from 'react';
import Scrollbar from 'src/common/components/Scrollbar';
import { SidebarContext } from 'src/common/contexts/SidebarContext';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Drawer, styled, useTheme } from '@mui/material';

import Logo from 'src/common/components/LogoSign';
import SidebarMenu from './SidebarMenu';
import SidebarFooter from './SidebarFooter';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        margin: ${theme.spacing(0, 2)};
        border-bottom: ${theme.sidebar.dividerBg} solid 1px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  // Responsiveness consts
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0
        }}
      >
        <TopSection>
          <Logo />
        </TopSection>
        <Scrollbar>
          <SidebarMenu />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box>
                <Logo />
              </Box>
            </TopSection>
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
        {isMediumScreen &&
            <SidebarFooter />
          }
      </Drawer>
    </>
  );
}

export default Sidebar;
