/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
    Typography,
    IconButton,
    Icon,
    styled,
    useTheme
} from '@mui/material';

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
    `
);

/**
 * 
 * @param {string} tooltip - The text that will appear in the tooltip
 * @param {string} icon - The text used to get the icon (use MUI icon, all lowercase, separeted by _ (e.g. RadioButtonCheckedIcon => radio_button_checked))
 * @param {function} onClick - The function to call when the button is clicked
 * @param {boolean} disabled - The bool to enable/disable the button
 * @param {string} cursor - The cursor icon
 * @returns A button with an icon (ideal for tables' actions)
 */
function TableIconButton(props) {

    const { tooltip, icon, onClick, disabled, cursor } = props;

    return (

        <Tooltip
            title={
                <React.Fragment>
                    <>
                        <Typography variant="body1" color="inherit">
                            {tooltip}
                        </Typography>
                    </>
                </React.Fragment>
            }
            followCursor
            arrow
        >
            <IconButtonWrapper
                size="small"
                sx={{
                    ml: 1,
                    cursor: cursor
                }}
            onClick={onClick}
            color="primary"
            disabled={disabled}
            >
                <Icon fontSize="small">
                    {icon}
                </Icon>
            </IconButtonWrapper>
        </Tooltip >

    )
}

TableIconButton.propTypes = {
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    cursor: PropTypes.string,
}

TableIconButton.defaultProps = {
    tooltip: 'Icon Button Tooltip',
    icon: 'radio_button_checked',
    cursor: 'pointer',
}

export default TableIconButton;