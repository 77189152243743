import { NavLink } from 'react-router-dom';
import {
  alpha,
  IconButton,
  Tooltip,
  styled,
  Icon,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    border-radius: ${theme.general.borderRadiusLg};
    background: ${alpha(theme.colors.primary.main, 0.1)};
    transition: ${theme.transitions.create(['background'])};
    color: ${theme.colors.primary.main};
    &:hover {
      background: ${alpha(theme.colors.primary.main, 0.2)};
    }
`
);

function HeaderButtonLink(props) {

  const { tooltipTitle, navlinkTo, children } = props;

  const { t } = useTranslation();

  return (
    <>
      <Tooltip arrow title={tooltipTitle ? t(`${tooltipTitle}`) : t('Tooltip')}>
        <IconButtonWrapper
          color="primary"
          component={NavLink}
          to={navlinkTo}
          target="_blank"
        >
          <Icon>
            {children}
          </Icon>
        </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default HeaderButtonLink;
