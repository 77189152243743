import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedCategory: null,
    categories: []
};

const slice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        // Set the Select Category that will be viewed on the categories page
        setSelectedCategory(state, action) {
            const category = action.payload;

            state.selectedCategory = category;
        },
        // Get Activities
        getCategories(state, action) {
            const categories = action.payload;

            state.categories = categories;
        },
        // Add Category
        addCategory(state, action) {
            const category = action.payload;

            state.categories = [...state.categories, category];
        },
        // Edit Category
        updateCategory(state, action) {
            const {id, data} = action.payload;

            state.categories = _.map(state.categories, (_category) => {
                if (_category.id === id) {
                    _category = { id, ...data }
                }

                return _category;
            })
        },
        // Archive Category
        archiveCategory(state, action) {
            const id = action.payload;

            state.categories = _.map(state.categories, (_category) => {
                if (_category.id === id) {
                    _category.inactive = true;
                    _category.inactive_date = new Date();
                }

                return _category;
            })
        },
        // unArchive Category
        unArchiveCategory(state, action) {
            const id = action.payload;

            state.categories = _.map(state.categories, (_category) => {
                if (_category.id === id) {
                    _category.inactive = false;
                    _category.inactive_date = undefined;
                }

                return _category;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedCategory = (categoryID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/categories/${categoryID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedCategory response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedCategory(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all categories
 */
export const getCategories = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/categories`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getCategories response: `), response.data)
                    }

                    dispatch(slice.actions.getCategories(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a category
 */
export const addCategory = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/categories`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addCategory response: `), response.data)
                    }

                    dispatch(slice.actions.addCategory(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a category
 */
export const updateCategory = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.updateCategory({id, data}));

        try {
            axios.patch(`/categories/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateCategory response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a category
 */
export const archiveCategory = (categoryID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveCategory(categoryID));

        try {
            axios.delete(`/categories/${categoryID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveCategory response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a category
 */
export const unArchiveCategory = (categoryID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveCategory(categoryID));

        try {
            axios.patch(`/categories/${categoryID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveCategory response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
