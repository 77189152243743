import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/common/components/SuspenseLoader';

import Authenticated from 'src/core/components/Authenticated';

import BaseLayout from 'src/common/layouts/BaseLayout';
import AccentSidebarLayout from 'src/common/layouts/AccentSidebarLayout';

import accountRoutes from 'src/core/routes/account';
import baseRoutes from 'src/common/routes/base';
import maintenanceRoutes from 'src/core/routes/maintenance';

import usersRoutes from 'src/modules/UserManagement/routes';
import equipmentRoutes from 'src/modules/EquipmentManagement/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loader(lazy(() => import('src/core/content/App/Home')));

// CSCHURMANN - 25/05/2022
// Function to dynamically determine the available routes based on the current subdomain.
export default function Routes () {

      const router = [
        {
          path: '*',
          element: <BaseLayout />,
          children: [...baseRoutes, ...accountRoutes]
        },
        {
          path: '/',
          element: <Navigate to="login" replace />
        },
        {
          path: '/app',
          element: (
            <Authenticated>
              <AccentSidebarLayout />
            </Authenticated>
          ),
          children: [
            {
              path: '/',
              element: <Home />
            },
            ...usersRoutes, 
            ...equipmentRoutes,
            ...maintenanceRoutes
          ]
        }
      ]

  return router;

}

