import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedModel: null,
    models: []
};

const slice = createSlice({
    name: 'models',
    initialState,
    reducers: {
        // Set the Select Model that will be viewed on the models page
        setSelectedModel(state, action) {
            const model = action.payload;

            state.selectedModel = model;
        },
        // Get Activities
        getModels(state, action) {
            const models = action.payload;

            state.models = models;
        },
        // Add Model
        addModel(state, action) {
            const model = action.payload;

            state.models = [...state.models, model];
        },
        // Edit Model
        updateModel(state, action) {
            const {id, data} = action.payload;

            state.models = _.map(state.models, (_model) => {
                if (_model.id === id) {
                    _model = { id, ...data }
                }

                return _model;
            })
        },
        // Archive Model
        archiveModel(state, action) {
            const id = action.payload;

            state.models = _.map(state.models, (_model) => {
                if (_model.id === id) {
                    _model.inactive = true;
                    _model.inactive_date = new Date();
                }

                return _model;
            })
        },
        // unArchive Model
        unArchiveModel(state, action) {
            const id = action.payload;

            state.models = _.map(state.models, (_model) => {
                if (_model.id === id) {
                    _model.inactive = false;
                    _model.inactive_date = undefined;
                }

                return _model;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedModel = (modelID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/models/${modelID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedModel response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedModel(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all models
 */
export const getModels = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/models`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getModels response: `), response.data)
                    }

                    dispatch(slice.actions.getModels(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a model
 */
export const addModel = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/models`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addModel response: `), response.data)
                    }

                    dispatch(slice.actions.addModel(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a model
 */
export const updateModel = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/models/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateModel response: `), response.data)
                    }

                    dispatch(slice.actions.updateModel({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a model
 */
export const archiveModel = (modelID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveModel(modelID));

        try {
            axios.delete(`/models/${modelID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveModel response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a model
 */
export const unArchiveModel = (modelID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveModel(modelID));

        try {
            axios.patch(`/models/${modelID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveModel response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
