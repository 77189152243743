
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid,
  Box,
  Card,
  Typography,
  Container,
  styled,
  Link
} from '@mui/material';

import useAuth from 'src/core/hooks/useAuth';
import Logo from 'src/common/components/LogoSign';

import Auth0Login from './Elements/LoginAuth0';

const MainContent = styled(Box)(
  () => `
  height: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
);

function LoginCover() {
  const { method, isAuthenticated } = useAuth();
  const { t } = useTranslation();

  // Responsiveness consts ---------------------------------------------------
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  // -------------------------------------------------------------------------

  // Grabbing the parameters in the URL (cannot use useParams because we don't use routes)
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const invitation = urlParams.get('invitation');
  const organization = urlParams.get('organization');
  const organizationName = urlParams.get('organization_name');

  return (
    <>
      <Helmet>
        <title>Legeneering IT Services - Login</title>
      </Helmet>

      {
        // If there is an invitation value it means the user has just accepted the invitation therefore we welcome them
        invitation !== null && invitation !== undefined ?
          <MainContent>
            <Container
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Card
                sx={{
                  p: 2,
                  mb: 2
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Logo logoPosition="center" />
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Typography
                      variant="h1"
                      sx={{
                        mt: { xs: 2, sm: 3, md: 4 },
                        mb: { xs: 1, sm: 2 }
                      }}
                    >
                      {t('Welcome to Legeneering IT Services!')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <Typography
                      variant="h3"
                      color="text.secondary"
                      fontWeight="normal"
                    >
                      {t(
                        "Thank you for accepting our invitation. Please SIGN-UP by using the button below."
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign="center">
                    <img
                      alt="Welcome"
                      // longdesc="https://www.freepik.com/vectors/business-collaboration-Business collaboration vector created by vectorjuice - www.freepik.com</a>"
                      height={isMediumScreen ? 300 : 300}
                      src="/static/images/status/welcome.svg"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Auth0Login invitation={invitation} organization={organization} orgName={organizationName} />
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </MainContent>
          // If not we show the general (boring) sign in form
          :
          <MainContent>
            <Container
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              maxWidth="sm"
            >
              <Card
                sx={{
                  p: 4,
                  my: 4
                }}
              >
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Logo logoPosition="center" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      textAlign="center"
                      variant="h3"
                    >
                      {t('Welcome to Legeneering IT Services!')}
                    </Typography>
                  </Grid>
                  {method === 'Auth0' &&
                    <Grid item xs={12}>
                      <Auth0Login />
                    </Grid>
                  }
                </Grid>
              </Card>
            </Container>
          </MainContent>
      }
    </>
  );
}

export default LoginCover;
