import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    invites: []
};

const slice = createSlice({
    name: 'invites',
    initialState,
    reducers: {
        getInvites(state, action) {
            const invites = action.payload;

            state.invites = invites;
        },
        addInvite(state, action) {
            const invite = action.payload;

            state.invites = [...state.invites, invite];
        },
        removeInvite(state, action) {
            const invite = action.payload;

            state.invites = _.reject(state.invites, (s) => s.id === invite);
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API to activate the profile after the user accepted the invitation
 * @param {*} invitation the ID of the invitation
 * @returns 
 */
export const acceptInvitation = (email) => {
    return new Promise((resolve, reject) => {
        try {
            let path = `/invite/${email}`

            axios.patch(path)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`acceptInvitation response: `), response.data)
                    }

                    resolve(response.data.result);
                })

        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API to get all the active invites
 * @returns 
 */
export const getInvites = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {

            axios.get(`/invites`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getInvites response: `), response.data)
                    }

                    dispatch(slice.actions.getInvites(response.data.result));

                    resolve();
                })

        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API to create an invitation for a new user
 * @param {*} data The object including all the information for the new user (i.e. first and last name etc.)
 * @returns 
 */
export const addInvite = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {

            if (process.env.NODE_ENV === 'development') {
                console.log(chalk.cyan(`addInvite data: ${data}`))
            }

            axios.post(`/invite`, data)
                .then(response => {
                
                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addInvite response: ${response.data}`))
                    }
                    
                    if (response.data.message === "User Profile Exists." || response.data.message === "Invitation Already Exists.")
                    {
                        reject(new Error(response.data.message));
                    }
                    else {
                        dispatch(slice.actions.addInvite(response.data.result));
    
                        resolve(response.data.message);
                    }
                })

        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the deleteInvite API
 * @param {*} invitation The invitation id to be deleted
 * @returns 
 */
export const removeInvite = (invitation) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {

            axios.delete(`/invite/${invitation}`)
                .then(() => {

                    dispatch(slice.actions.removeInvite({ invitation }))

                    resolve();
                })

        }
        catch (err) {
            reject(err);
        }
    })
}

export default slice;
