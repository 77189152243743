/* eslint-disable */
import { createContext, useEffect, useReducer } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
// import { useIdleTimer } from 'react-idle-timer' // Documentation: https://idletimer.dev/docs/getting-started/installation // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
import { auth0Config } from 'src/config';
import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack'; // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
import chalk from 'chalk';
import axios from 'src/common/utils/axios';
import { getProfile } from 'src/core/slices/profiles';
import { acceptInvitation } from 'src/core/slices/invites';
// import createSnackbar from 'src/common/utils/createSnackbar'; // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
import { useNavigate } from 'react-router';
import { useDispatch } from 'src/store';

let auth0Client = null;

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    preferences: {
      theme: 'PureLightTheme',
      notificationsSettings: {
        weeklyReportChecked: false,
        orderStatusUpdateChecked: false
      }
    }
  },
  accessToken: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accessToken } = action.payload;


    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      accessToken
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  CHANGEUSERDETAILS: (state, action) => {

    const { details } = action.payload;

    return {
      ...state,
      user: {
        ...state.user,
        ...details,
      }
    }

  },
  CHANGETHEME: (state, action) => {

    const { theme } = action.payload;

    let tempUserPreferences = { ...state.user.generalPreferences };

    tempUserPreferences.theme = theme;

    return {
      ...state,
      user: {
        ...state.user,
        generalPreferences: tempUserPreferences
      }
    }

  }
};

function getCookie(name) {
  let res = null;
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) res = parts.pop().split(';').shift();

  return res;
}

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  loginWithRedirect: () => Promise.resolve(),
  handleRedirectCallback: () => Promise.resolve(),
  changeUserOrganizaton: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  getToken: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  updateTheme: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const storeDispatch = useDispatch();
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
  // let autoLogoutIdleTimer;
  // let autoLogoutIdlePrompt = null;

  // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
  // After a certain time we display a snackbar advising the user that they will soon be automatically logged out
  // const onPrompt = () => {

  //   if (!autoLogoutIdlePrompt) {
  //     autoLogoutIdlePrompt = createSnackbar({
  //       enqueueSnackbar,
  //       message: `You are about to be logged out in 5 minutes.`,
  //       type: "info"
  //     });
  //   }

  // }

  // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
  // onIdle Function to call when user is idle. (https://idletimer.dev/docs/api/props#onidle)
  // const onIdle = () => {
  //   logout()
  // }

  // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
  // Function to call on user activity. (https://idletimer.dev/docs/api/props#onaction)
  // const onAction = async () => {

  //   if (autoLogoutIdlePrompt) {
  //     closeSnackbar(autoLogoutIdlePrompt);
  //   }

  //   const isAuthenticated = await auth0Client.isAuthenticated();

  //   // If the user is authenticated on every action we re-get the access token and reset the timer
  //   if (isAuthenticated) {

  //     autoLogoutIdleTimer.reset();

  //     const accessToken = await auth0Client.getTokenSilently();

  //     axios.defaults.headers.common = { 'Authorization': `Bearer ${accessToken}` }
  //   }
  //   // If the user is NOT authenticated we log them out
  //   else {
  //     logout();
  //   }

  // }

  // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
  // useIdleTimer usage: https://idletimer.dev/docs/api/use-idle-timer#usage
  // autoLogoutIdleTimer = useIdleTimer({
  //   onPrompt,
  //   onIdle,
  //   onAction,
  //   timeout: 1000 * 60 * 5, // 5 minutes
  //   promptTimeout: 1000 * 60 * 60 * 2, // 2 hours
  //   events: [
  //     'keydown',
  //     'wheel',
  //     'DOMMouseScroll',
  //     'mousewheel',
  //     'mousedown',
  //     'touchstart',
  //     'touchmove',
  //     'MSPointerDown',
  //     'visibilitychange'
  //   ],
  //   startOnMount: false,
  //   startManually: true,
  //   crossTab: true
  // })

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          redirect_uri: window.location.origin,
          ...auth0Config,
          cacheLocation: 'localstorage'
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        var userDetails = {};

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const accessToken = await auth0Client.getTokenSilently();

          axios.defaults.headers.common = { 'Authorization': `Bearer ${accessToken}` }

          if (process.env.NODE_ENV === 'development') {
            console.log(chalk.cyan(`accesstoken: ${accessToken}`))
          }

          const profile = await getProfile();

          // When auth0 context is initialised we reset (precaution) start the auto logout timer
          // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
          // autoLogoutIdleTimer.reset();
          // autoLogoutIdleTimer.start();

          userDetails = {
            id: user.sub,
            jobTitle: profile.job_title,
            avatar: profile.avatar || user.picture,
            email: user.email,
            name: user.name,
            firstName: profile.first_name,
            lastName: profile.last_name,
            phone: user.phone,
            generalPreferences: profile.general_preferences,
            notificationPreferences: profile.notification_preferences,
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: userDetails,
              accessToken
            }
          });

        } else {

          userDetails = {
            id: "demoUser",
            jobTitle: "Demo User",
            name: "Demo User",
            firstName: "Demo",
            lastName: "User",
            role: "user",
            username: "demoUser",
            generalPreferences: {
              theme: 'PureLightTheme',
            },
            notificationPreferences: {
              weeklyReportChecked: false,
              orderStatusUpdateChecked: false
            }
          }

          // If the user is not authenticated we are dispatching a demo user to be able to use the viewer on the landing page
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              // user: null
              user: userDetails
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const handleRedirectCallback = async () => {

    await auth0Client.handleRedirectCallback();
    window.history.replaceState({}, document.title, "/");

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      let accessToken = await auth0Client.getTokenSilently();

      axios.defaults.headers.common = { 'Authorization': `Bearer ${accessToken}` }

      if (process.env.NODE_ENV === 'development') {
        console.log(chalk.cyan(`user: `), user)
        console.log(chalk.cyan(`accesstoken: ${accessToken}`))
      }

      let profile = {};

      profile = await getProfile();

      if (profile === undefined || profile === null || profile.user_id === undefined || profile.user_id === null) {
        profile = await acceptInvitation(user.email)
      }

      accessToken = await auth0Client.getTokenSilently();

      axios.defaults.headers.common = { 'Authorization': `Bearer ${accessToken}` }

      let payload = {
        user: {
          id: user.sub,
          jobTitle: profile && profile.job_title || '',
          avatar: profile.avatar || user.picture,
          email: user.email,
          name: user.name,
          firstName: profile && profile.first_name || '',
          lastName: profile && profile.last_name || '',
          phone: user.phone,
          generalPreferences: profile && profile.general_preferences || {},
          notificationPreferences: profile && profile.notification_preferences || {},
        }
      }

      if (process.env.NODE_ENV === 'development') {
        console.log(chalk.cyan(`payload: `), payload)
      }

      // When login happens we reset (precaution) start the auto logout timer
      // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
      // autoLogoutIdleTimer.reset();
      // autoLogoutIdleTimer.start();

      dispatch({
        type: 'LOGIN',
        payload
      });

      if (user.org_id !== undefined)
        navigate(`/app`)
    }
  }

  const loginWithRedirect = async (options) => {
    await auth0Client.loginWithRedirect(options);
  };

  const logout = () => {

    axios.defaults.headers.common = {}

    // TODO: Auto logout commented to go live. Double check it before publishing as it hasn't been working entirely correctly
    // autoLogoutIdleTimer.pause();
    // autoLogoutIdleTimer.reset();

    // eslint-disable-next-line
    const domain = (/:\/\/([^\/]+)/).exec(window.location.href)[1];

    auth0Client.logout({
      returnTo: `https://${domain}`
    });
    dispatch({
      type: 'LOGOUT'
    });
  };

  const getToken = async () => {

    return auth0Client.getTokenSilently();

  }

  const refreshToken = async () => {
    
    const accessToken = await auth0Client.getTokenSilently();

    axios.defaults.headers.common = { 'Authorization': `Bearer ${accessToken}` };
  }

  const updateUser = (details) => {

    return new Promise((resolve, reject) => {

      try {

        dispatch({
          type: "CHANGEUSERDETAILS",
          payload: {
            details,
          }
        })

        axios.patch(`/profile`, { details })
          .then(() => {
            resolve();
          });

      }
      catch (err) {
        reject(err);
      }
    })
  }


  const updateTheme = (theme) => {
    return new Promise((resolve, reject) => {

      try {

        dispatch({
          type: "CHANGETHEME",
          payload: {
            theme
          }
        })

        axios.patch(`/profile/${state.user.id}/preferences/general/theme`, {
          general_preferences_id: state.user.generalPreferences.id,
          theme
        })
          .then(() => {
            resolve();
          });

      }
      catch (err) {
        reject(err);
      }
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithRedirect,
        handleRedirectCallback,
        logout,
        getToken,
        updateUser,
        updateTheme,
        refreshToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
