import useAuth from 'src/core/hooks/useAuth';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ComputerOutlined from '@mui/icons-material/ComputerOutlined';
import GavelOutlined from '@mui/icons-material/GavelOutlined';
import BookOnlineOutlined from '@mui/icons-material/BookOnlineOutlined';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function MenuItems() {

  let result = [
  {
    heading: 'General',
    items: [
      {
        name: 'Home',
        icon: HomeOutlinedIcon,
        link: `/app`
      },
      {
        name: 'Calendar',
        icon: CalendarMonthOutlinedIcon,
        link: `/app/calendar`
      },
      {
        name: 'Reports',
        icon: AssessmentOutlinedIcon,
        link: `/app/reports`
      }
    ]
  },
  {
    heading: 'Account Management',
    items: [
      {
        name: 'Users',
        icon: PersonOutlinedIcon,
        link: `/app/users`
      },
      {
        name: 'Departments',
        icon: PeopleOutlineOutlinedIcon,
        link: `/app/departments`
      },
      {
        name: 'Roles',
        icon: PeopleOutlineOutlinedIcon,
        link: `/app/roles`
      },
      {
        name: 'Security Groups',
        icon: PeopleOutlineOutlinedIcon,
        link: `/app/securitygroups`
      }
    ]
  },
  {
    heading: 'Asset Management',
    items: [
      {
        name: 'Assets',
        icon: ComputerOutlined,
        link: `/app/assets`
      },
      {
        name: 'Accessories',
        icon: KeyboardOutlinedIcon,
        link: `/app/accessories`
      },
      {
        name: 'Licenses',
        icon: ComputerOutlined,
        link: `/app/licenses`
      },
      {
        name: 'Consumables',
        icon: ComputerOutlined,
        link: `/app/consumables`
      },
      {
        name: 'Service Contracts',
        icon: GavelOutlined,
        link: `/app/servicecontracts`
      },
      {
        name: 'Bookings',
        icon: BookOnlineOutlined,
        link: `/app/bookings`
      }
    ]
  }];
  return result;
};
