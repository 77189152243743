import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    profiles: []
};

const slice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        getProfiles(state, action) {
            const profiles = action.payload;

            state.profiles = profiles;
        },
        addProfiles(state, action) {
            const profiles = action.payload;

            state.profiles = [...state.profiles, ...profiles];
        },
        updateProfile(state, action) {
            const { profile, details } = action.payload;

            state.profiles = _.map(state.profiles, (_profile) => {
                if (_profile.id === profile.id) {
                    _profile.first_name = details.firstName;
                    _profile.last_name = details.lastName;
                }

                return _profile;
            })
        },
        removeProfile(state, action) {
            const id = action.payload;

            state.profiles = _.reject(state.users, { id });
        }
    }
});

export const reducer = slice.reducer;

export const getProfile = async (user) => {
    let path = `/profile`

    if (user !== undefined)
        path = `/profile/${user}`

    const response = await axios.get(path)

    return response.data.result;
};

/**
 * Function that calls the API and gets all the active profiles in the organization
 * @returns The list of active profiles
 */
export const getProfiles = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/profiles`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getProfiles response: `), response.data)
                    }

                    dispatch(slice.actions.getProfiles(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

export const addProfiles = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/profiles`, data)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addProfiles response: `), response.data)
                    }

                    dispatch(slice.actions.addProfiles(response.data));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that updates the user profile
 * @param {*} user The id of the user to update
 * @param {*} data The values to be updated
 * @returns 
 */
export const updateProfile = (user, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.patch(`/profile/${user}`, data)
                .then((response) => {
                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateProfile response: `), response.data)
                    }

                    dispatch(slice.actions.updateProfile({ user, details: data}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
}

/**
 * Function that deletes a user from Auth0 as well as their profile in the db
 * @param {*} user The id of the user profile to delete
 * @returns 
 */
export const removeProfile = (user) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.delete(`/profile/${user}`)
                .then(() => {

                    dispatch(slice.actions.removeProfile(user));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
