import React from 'react';

import useAuth from 'src/core/hooks/useAuth';

import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { themeCreator } from './base';

// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import stylisRTLPlugin from 'stylis-plugin-rtl';

// const cacheRtl = createCache({
//   key: 'bloom-ui',
// prepend: true,
//
//   stylisPlugins: [stylisRTLPlugin]
// });

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {

  const { user, updateTheme } = useAuth();

  const activeTheme = themeCreator((user && user.generalPreferences && user.generalPreferences.theme) || 'PureLightTheme');
  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    updateTheme(themeName);
  };

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={activeTheme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
