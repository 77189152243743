import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedSecurityGroup: null,
    securityGroups: []
};

const slice = createSlice({
    name: 'securityGroups',
    initialState,
    reducers: {
        // Set the Select SecurityGroup that will be viewed on the securityGroups page
        setSelectedSecurityGroup(state, action) {
            const securityGroup = action.payload;

            state.selectedSecurityGroup = securityGroup;
        },
        // Get Activities
        getSecurityGroups(state, action) {
            const securityGroups = action.payload;

            state.securityGroups = securityGroups;
        },
        // Add SecurityGroup
        addSecurityGroup(state, action) {
            const securityGroup = action.payload;

            state.securityGroups = [...state.securityGroups, securityGroup];
        },
        // Edit SecurityGroup
        updateSecurityGroup(state, action) {
            const {id, data} = action.payload;

            state.securityGroups = _.map(state.securityGroups, (_securityGroup) => {
                if (_securityGroup.id === id) {
                    _securityGroup = { id, ...data }
                }

                return _securityGroup;
            })
        },
        // Archive SecurityGroup
        archiveSecurityGroup(state, action) {
            const id = action.payload;

            state.securityGroups = _.map(state.securityGroups, (_securityGroup) => {
                if (_securityGroup.id === id) {
                    _securityGroup.inactive = true;
                    _securityGroup.inactive_date = new Date();
                }

                return _securityGroup;
            })
        },
        // unArchive SecurityGroup
        unArchiveSecurityGroup(state, action) {
            const id = action.payload;

            state.securityGroups = _.map(state.securityGroups, (_securityGroup) => {
                if (_securityGroup.id === id) {
                    _securityGroup.inactive = false;
                    _securityGroup.inactive_date = undefined;
                }

                return _securityGroup;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedSecurityGroup = (securityGroupID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/securityGroups/${securityGroupID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedSecurityGroup response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedSecurityGroup(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all securityGroups
 */
export const getSecurityGroups = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/securityGroups`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getSecurityGroups response: `), response.data)
                    }

                    dispatch(slice.actions.getSecurityGroups(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a securityGroup
 */
export const addSecurityGroup = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/securityGroups`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addSecurityGroup response: `), response.data)
                    }

                    dispatch(slice.actions.addSecurityGroup(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a securityGroup
 */
export const bookSecurityGroup = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.post(`/securityGroups/${id}/book`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`bookSecurityGroup response: `), response.data)
                    }

                    dispatch(slice.actions.bookSecurityGroup({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a securityGroup
 */
export const updateSecurityGroup = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/securityGroups/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateSecurityGroup response: `), response.data)
                    }

                    dispatch(slice.actions.updateSecurityGroup({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a securityGroup
 */
export const archiveSecurityGroup = (securityGroupID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveSecurityGroup(securityGroupID));

        try {
            axios.delete(`/securityGroups/${securityGroupID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveSecurityGroup response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a securityGroup
 */
export const unArchiveSecurityGroup = (securityGroupID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveSecurityGroup(securityGroupID));

        try {
            axios.patch(`/securityGroups/${securityGroupID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveSecurityGroup response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
