import axios from 'axios';

let tenant = process.env.REACT_APP_AUTH0_ORGANISATION_ID;

const axiosInt = axios.create({
  // baseURL: "http://localhost:7071/api",
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  timeout: 100000000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'x-tenant-id': tenant
  }
});

const setToken = (token) => {
  axiosInt.defaults.headers.Authorization = `Bearer ${token}`;
}

export {
  setToken
};

export default axiosInt;
