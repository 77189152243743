import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedSupplier: null,
    suppliers: []
};

const slice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        // Set the Select Supplier that will be viewed on the suppliers page
        setSelectedSupplier(state, action) {
            const supplier = action.payload;

            state.selectedSupplier = supplier;
        },
        // Get Activities
        getSuppliers(state, action) {
            const suppliers = action.payload;

            state.suppliers = suppliers;
        },
        // Add Supplier
        addSupplier(state, action) {
            const supplier = action.payload;

            state.suppliers = [...state.suppliers, supplier];
        },
        // Edit Supplier
        updateSupplier(state, action) {
            const {id, data} = action.payload;

            state.suppliers = _.map(state.suppliers, (_supplier) => {
                if (_supplier.id === id) {
                    _supplier = { id, ...data }
                }

                return _supplier;
            })
        },
        // Archive Supplier
        archiveSupplier(state, action) {
            const id = action.payload;

            state.suppliers = _.map(state.suppliers, (_supplier) => {
                if (_supplier.id === id) {
                    _supplier.inactive = true;
                    _supplier.inactive_date = new Date();
                }

                return _supplier;
            })
        },
        // unArchive Supplier
        unArchiveSupplier(state, action) {
            const id = action.payload;

            state.suppliers = _.map(state.suppliers, (_supplier) => {
                if (_supplier.id === id) {
                    _supplier.inactive = false;
                    _supplier.inactive_date = undefined;
                }

                return _supplier;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedSupplier = (supplierID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/suppliers/${supplierID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedSupplier response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedSupplier(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all suppliers
 */
export const getSuppliers = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/suppliers`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getSuppliers response: `), response.data)
                    }

                    dispatch(slice.actions.getSuppliers(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a supplier
 */
export const addSupplier = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/suppliers`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addSupplier response: `), response.data)
                    }

                    dispatch(slice.actions.addSupplier(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a supplier
 */
export const updateSupplier = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/suppliers/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateSupplier response: `), response.data)
                    }

                    dispatch(slice.actions.updateSupplier({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a supplier
 */
export const archiveSupplier = (supplierID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveSupplier(supplierID));

        try {
            axios.delete(`/suppliers/${supplierID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveSupplier response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a supplier
 */
export const unArchiveSupplier = (supplierID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveSupplier(supplierID));

        try {
            axios.patch(`/suppliers/${supplierID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveSupplier response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
