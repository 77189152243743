import {
  Box,
  styled,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from 'src/core/hooks/useAuth';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 100%;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 100%;
        height: 100%;
        // width: 62.4px;
        // height: 45.6px;
`
);

const BadgeWrapper = styled(Box)(
  () => `
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-45deg);
      transform-origin: 45% 375%;
      zIndex: 9999,
`
);

const Badge = styled(Box)(
  ({ theme }) => `
        text-align: center;
        display: inline-block;
        font-size: ${theme.typography.pxToRem(12)};
        width: 150px;
`
);

function Logo({ logoPosition, hideBanner }) {
  const theme = useTheme();
  const { organization } = useAuth();

  return (
    <LogoWrapper to={(organization === null || organization === undefined) ? '/' : `/${organization.name}`}>
      <LogoSignWrapper display="flex" justifyContent={logoPosition} alignItems="center">
        <img src='/static/images/logo/nonTransparentLogo.png' alt='logo' style={{ width: "100%", height: "100%", maxWidth: "70px", maxHeight: "70px", padding: "5px", }} />
      </LogoSignWrapper>
      {hideBanner !== true && <Box
        component="span"
      >
        <BadgeWrapper>
          {process.env.NODE_ENV === 'development' &&
            <Badge
              sx={{
                backgroundColor: `${theme.palette.success.main}`,
                color: `${theme.palette.success.contrastText}`
              }}
            >
              DEV
            </Badge>
          }
          {process.env.NODE_ENV === 'sandbox' &&
            <Badge
              sx={{
                backgroundColor: `${theme.palette.warning.main}`,
                color: `${theme.palette.warning.contrastText}`
              }}
            >
              SANDBOX
            </Badge>
          }
          {process.env.NODE_ENV === 'production' &&
            <Badge
              sx={{
                backgroundColor: `${theme.palette.error.main}`,
                color: `${theme.palette.error.contrastText}`
              }}
            >
              BETA
            </Badge>
          }

        </BadgeWrapper>
      </Box>}
    </LogoWrapper >
  );
}

export default Logo;
