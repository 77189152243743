import {
  Box,
} from '@mui/material';

import { ThemeSwitcher, HeaderButtonLink } from 'src/common/components/Buttons';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

function SidebarFooter() {

  return (
    <Box
      sx={{
        height: 60
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <ThemeSwitcher />
      <HeaderButtonLink
        tooltipTitle="Documentation"
        navlinkTo="/docs"
      >
        <HelpOutlineOutlinedIcon />
      </HeaderButtonLink>
    </Box>
  );
}

export default SidebarFooter;
