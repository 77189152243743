import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import Routes from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/core/hooks/useAuth';

import { LicenseInfo } from '@mui/x-license-pro';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './common/theme/ThemeProvider';
import AppInit from './common/components/AppInit';


function App() {
  const [redirectHandled, setRedirectHandled] = useState(false);
  const content = useRoutes(Routes());
  const auth = useAuth();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const state = urlParams.get('state');
  const code = urlParams.get('code');
  const error = urlParams.get('error');

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE_KEY);

  useEffect(() => {

    if (auth.isInitialized) {
      const handleAuth0RedirectCallback = async () => {
        await auth.handleRedirectCallback();
        setRedirectHandled(true);
      }
  
      if ((state !== undefined && state !== null) &&
        ((code !== undefined && code !== null) ||
        (error !== undefined && error !== null))) {
            handleAuth0RedirectCallback();
      }
      else {
        setRedirectHandled(true);
      }
    }

  }, [auth.isInitialized])

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {auth.isInitialized && redirectHandled ? content : <AppInit />}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
