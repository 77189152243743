import { useRef } from 'react';

// import useAuth from 'src/core/hooks/useAuth';

// import { updateThemePreference } from 'src/core/slices/userMetadata';

import {
  IconButton,
  alpha,
  Tooltip,
  styled,
  useTheme,
} from '@mui/material';

import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import NightsStayTwoToneIcon from '@mui/icons-material/NightsStayTwoTone';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/core/hooks/useAuth';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function ThemeSwitcher() {

  // const { user } = useAuth();

  const ref = useRef(null);
  const { t } = useTranslation();
  const { user, updateTheme } = useAuth();
  const appTheme = useTheme();

  const handleChangeTheme = () => {
    updateTheme(user.generalPreferences.theme === 'PureLightTheme' ? 'NebulaFighterTheme' : 'PureLightTheme')
  }

  return (
    <>
      <Tooltip arrow title={user.generalPreferences.theme === 'PureLightTheme' ? t('Switch to Dark Theme') : t('Switch to Light Theme')}>
        <IconButtonWrapper
          color="secondary"
          ref={ref}
          onClick={handleChangeTheme}
          sx={{
            mx: 1,
            background: alpha(user && user.generalPreferences && user.generalPreferences.theme === 'NebulaFighterTheme' ? appTheme.colors.warning.main : appTheme.colors.primary.main, 0.1),
            transition: `${appTheme.transitions.create(['background'])}`,
            color: user && user.generalPreferences && user.generalPreferences.theme === 'NebulaFighterTheme' ? appTheme.colors.warning.main : appTheme.colors.primary.main,

            '&:hover': {
              background: alpha(user && user.generalPreferences && user.generalPreferences.theme === 'NebulaFighterTheme' ? appTheme.colors.warning.main : appTheme.colors.primary.main, 0.2)
            }
          }}
        >
          {user.generalPreferences.theme === 'NebulaFighterTheme' ? 
            <WbSunnyTwoToneIcon fontSize="small" /> :
            <NightsStayTwoToneIcon fontSize="small" />}
        </IconButtonWrapper>
      </Tooltip>
    </>
  );
}

export default ThemeSwitcher;
