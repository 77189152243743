import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedDepartment: null,
    departments: []
};

const slice = createSlice({
    name: 'departments',
    initialState,
    reducers: {
        // Set the Select Department that will be viewed on the departments page
        setSelectedDepartment(state, action) {
            const department = action.payload;

            state.selectedDepartment = department;
        },
        // Get Activities
        getDepartments(state, action) {
            const departments = action.payload;

            state.departments = departments;
        },
        // Add Department
        addDepartment(state, action) {
            const department = action.payload;

            state.departments = [...state.departments, department];
        },
        // Edit Department
        updateDepartment(state, action) {
            const {id, data} = action.payload;

            state.departments = _.map(state.departments, (_department) => {
                if (_department.id === id) {
                    _department = { id, ...data }
                }

                return _department;
            })
        },
        // Archive Department
        archiveDepartment(state, action) {
            const id = action.payload;

            state.departments = _.map(state.departments, (_department) => {
                if (_department.id === id) {
                    _department.inactive = true;
                    _department.inactive_date = new Date();
                }

                return _department;
            })
        },
        // unArchive Department
        unArchiveDepartment(state, action) {
            const id = action.payload;

            state.departments = _.map(state.departments, (_department) => {
                if (_department.id === id) {
                    _department.inactive = false;
                    _department.inactive_date = undefined;
                }

                return _department;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedDepartment = (departmentID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/departments/${departmentID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedDepartment response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedDepartment(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all departments
 */
export const getDepartments = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/departments`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getDepartments response: `), response.data)
                    }

                    dispatch(slice.actions.getDepartments(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a department
 */
export const addDepartment = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/departments`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addDepartment response: `), response.data)
                    }

                    dispatch(slice.actions.addDepartment(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a department
 */
export const bookDepartment = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.post(`/departments/${id}/book`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`bookDepartment response: `), response.data)
                    }

                    dispatch(slice.actions.bookDepartment({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a department
 */
export const updateDepartment = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/departments/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateDepartment response: `), response.data)
                    }

                    dispatch(slice.actions.updateDepartment({id, data: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a department
 */
export const archiveDepartment = (departmentID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveDepartment(departmentID));

        try {
            axios.delete(`/departments/${departmentID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveDepartment response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a department
 */
export const unArchiveDepartment = (departmentID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveDepartment(departmentID));

        try {
            axios.patch(`/departments/${departmentID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveDepartment response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
