import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedManufacturer: null,
    manufacturers: []
};

const slice = createSlice({
    name: 'manufacturers',
    initialState,
    reducers: {
        // Set the Select Manufacturer that will be viewed on the manufacturers page
        setSelectedManufacturer(state, action) {
            const manufacturer = action.payload;

            state.selectedManufacturer = manufacturer;
        },
        // Get Activities
        getManufacturers(state, action) {
            const manufacturers = action.payload;

            state.manufacturers = manufacturers;
        },
        // Add Manufacturer
        addManufacturer(state, action) {
            const manufacturer = action.payload;

            state.manufacturers = [...state.manufacturers, manufacturer];
        },
        // Edit Manufacturer
        updateManufacturer(state, action) {
            const {id, data} = action.payload;

            state.manufacturers = _.map(state.manufacturers, (_manufacturer) => {
                if (_manufacturer.id === id) {
                    _manufacturer = { id, ...data }
                }

                return _manufacturer;
            })
        },
        // Archive Manufacturer
        archiveManufacturer(state, action) {
            const id = action.payload;

            state.manufacturers = _.map(state.manufacturers, (_manufacturer) => {
                if (_manufacturer.id === id) {
                    _manufacturer.inactive = true;
                    _manufacturer.inactive_date = new Date();
                }

                return _manufacturer;
            })
        },
        // unArchive Manufacturer
        unArchiveManufacturer(state, action) {
            const id = action.payload;

            state.manufacturers = _.map(state.manufacturers, (_manufacturer) => {
                if (_manufacturer.id === id) {
                    _manufacturer.inactive = false;
                    _manufacturer.inactive_date = undefined;
                }

                return _manufacturer;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedManufacturer = (manufacturerID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/manufacturers/${manufacturerID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedManufacturer response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedManufacturer(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all manufacturers
 */
export const getManufacturers = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/manufacturers`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getManufacturers response: `), response.data)
                    }

                    dispatch(slice.actions.getManufacturers(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a manufacturer
 */
export const addManufacturer = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/manufacturers`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addManufacturer response: `), response.data)
                    }

                    dispatch(slice.actions.addManufacturer(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a manufacturer
 */
export const updateManufacturer = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.updateManufacturer({id, data}));

        try {
            axios.patch(`/manufacturers/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateManufacturer response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a manufacturer
 */
export const archiveManufacturer = (manufacturerID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveManufacturer(manufacturerID));

        try {
            axios.delete(`/manufacturers/${manufacturerID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveManufacturer response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a manufacturer
 */
export const unArchiveManufacturer = (manufacturerID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveManufacturer(manufacturerID));

        try {
            axios.patch(`/manufacturers/${manufacturerID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveManufacturer response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
