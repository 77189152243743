import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedLocation: null,
    locations: []
};

const slice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        // Set the Select Location that will be viewed on the locations page
        setSelectedLocation(state, action) {
            const location = action.payload;

            state.selectedLocation = location;
        },
        // Get Activities
        getLocations(state, action) {
            const locations = action.payload;

            state.locations = locations;
        },
        // Add Location
        addLocation(state, action) {
            const location = action.payload;

            state.locations = [...state.locations, location];
        },
        // Edit Location
        updateLocation(state, action) {
            const {id, data} = action.payload;

            state.locations = _.map(state.locations, (_location) => {
                if (_location.id === id) {
                    _location = { id, ...data }
                }

                return _location;
            })
        },
        // Archive Location
        archiveLocation(state, action) {
            const id = action.payload;

            state.locations = _.map(state.locations, (_location) => {
                if (_location.id === id) {
                    _location.inactive = true;
                    _location.inactive_date = new Date();
                }

                return _location;
            })
        },
        // unArchive Location
        unArchiveLocation(state, action) {
            const id = action.payload;

            state.locations = _.map(state.locations, (_location) => {
                if (_location.id === id) {
                    _location.inactive = false;
                    _location.inactive_date = undefined;
                }

                return _location;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedLocation = (locationID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/locations/${locationID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedLocation response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedLocation(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all locations
 */
export const getLocations = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/locations`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getLocations response: `), response.data)
                    }

                    dispatch(slice.actions.getLocations(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a location
 */
export const addLocation = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/locations`, {data})
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addLocation response: `), response.data)
                    }

                    dispatch(slice.actions.addLocation(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a location
 */
export const updateLocation = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.updateLocation({id, data}));

        try {
            axios.patch(`/locations/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateLocation response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a location
 */
export const archiveLocation = (locationID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveLocation(locationID));

        try {
            axios.delete(`/locations/${locationID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveLocation response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a location
 */
export const unArchiveLocation = (locationID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveLocation(locationID));

        try {
            axios.patch(`/locations/${locationID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveLocation response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
