import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import chalk from 'chalk';

import axios from 'src/common/utils/axios';

const initialState = {
    selectedAsset: null,
    assets: []
};

const slice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        // Set the Select Asset that will be viewed on the assets page
        setSelectedAsset(state, action) {
            const asset = action.payload;

            state.selectedAsset = asset;
        },
        // Get Activities
        getAssets(state, action) {
            const assets = action.payload;

            state.assets = assets;
        },
        // Add Asset
        addAsset(state, action) {
            const asset = action.payload;

            state.assets = [...state.assets, asset];
        },
        // Edit Asset
        updateAsset(state, action) {
            const { id, data } = action.payload;

            state.assets = _.map(state.assets, (_asset) => {
                if (_asset.id === id) {
                    _asset = { id, ...data }
                }

                return _asset;
            })
        },
        // Book Asset
        bookAsset(state, action) {
            const { id, data } = action.payload;

            state.assets = _.map(state.assets, (_asset) => {
                if (_asset.id === id) {
                    _asset.current_booking = data.user_id ? data.user.display_name : data.location.name;
                    _asset.current_booking_details = data
                    _asset.status = "Deployed"
                }

                return _asset;
            })
        },
        // Return Asset
        returnAsset(state, action) {
            const {id, result} = action.payload;

            state.assets = _.map(state.assets, (_asset) => {
                if (_asset.id === id) {

                    const _booking = _asset.current_booking_details;

                    _asset.current_booking = "Not Booked";
                    delete _asset.current_booking_details;

                    _asset.last_booking = _booking.user_id ? _booking.user.display_name : _booking.location.name;
                    _asset.last_booking_details = _booking;
                    _asset.status = result?.status || "Deployable"
                }

                return _asset;
            })
        },
        // Archive Asset
        archiveAsset(state, action) {
            const id = action.payload;

            state.assets = _.map(state.assets, (_asset) => {
                if (_asset.id === id) {
                    _asset.inactive = true;
                    _asset.inactive_date = new Date();
                }

                return _asset;
            })
        },
        // unArchive Asset
        unArchiveAsset(state, action) {
            const id = action.payload;

            state.assets = _.map(state.assets, (_asset) => {
                if (_asset.id === id) {
                    _asset.inactive = false;
                    _asset.inactive_date = undefined;
                }

                return _asset;
            })
        }
    }
});

export const reducer = slice.reducer;

/**
 * Function that calls the API and gets all the details of the provided ID
 */
export const setSelectedAsset = (assetID) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/assets/${assetID}`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`setSelectedAsset response: `), response.data)
                    }

                    dispatch(slice.actions.setSelectedAsset(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets all assets
 */
export const getAssets = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/assets`)
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getAssets response: `), response.data)
                    }

                    dispatch(slice.actions.getAssets(response.data.result));

                    resolve();
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets count of assets each month between dates
 */
export const getAssetCount = async (startDate, endDate) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/assets/count`, { params: { startDate, endDate }})
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getAssetCounts response: `), response.data)
                    }

                    resolve(response.data.result);
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and gets count of assets by location
 */
export const getAssetLocations = async (startDate, endDate) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`/assets/location`, { params: { startDate, endDate }})
                .then((response) => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`getAssetLocations response: `), response.data)
                    }

                    resolve(response.data.result);
                })
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and creates a asset
 */
export const addAsset = (data) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(`/assets`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`addAsset response: `), response.data)
                    }

                    dispatch(slice.actions.addAsset(response.data.result));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and books an asset
 */
export const bookAsset = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.post(`/assets/${id}/book`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`bookAsset response: `), response.data)
                    }

                    dispatch(slice.actions.bookAsset({ id, data: response.data.result }));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and return an asset
 */
export const returnAsset = (id, booking, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.post(`/assets/${id}/return/${booking}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`returnAsset response: `), response.data)
                    }

                    dispatch(slice.actions.returnAsset({id, result: response.data.result}));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and updates a asset
 */
export const updateAsset = (id, data) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        try {
            axios.patch(`/assets/${id}`, { data })
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`updateAsset response: `), response.data)
                    }

                    dispatch(slice.actions.updateAsset({ id, data: response.data.result }));

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and archived a asset
 */
export const archiveAsset = (assetID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.archiveAsset(assetID));

        try {
            axios.delete(`/assets/${assetID}`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`archiveAsset response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

/**
 * Function that calls the API and unArchived a asset
 */
export const unArchiveAsset = (assetID) => async (dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch(slice.actions.unArchiveAsset(assetID));

        try {
            axios.patch(`/assets/${assetID}/restore`)
                .then(response => {

                    if (process.env.NODE_ENV === 'development') {
                        console.log(chalk.cyan(`unArchiveAsset response: `), response.data)
                    }

                    resolve();
                });
        }
        catch (err) {
            reject(err);
        }
    })
};

export default slice;
