/* eslint-disable */
import { useState } from 'react';
import { Grid, Button, Alert } from '@mui/material';
import useAuth from 'src/core/hooks/useAuth';
import useRefMounted from 'src/common/hooks/useRefMounted';
import LoginIcon from '@mui/icons-material/Login';

import { useTranslation } from 'react-i18next';

function LoginAuth0(props) {

  const { invitation, organization } = props;

  const { loginWithRedirect } = useAuth();
  const [error, setError] = useState(null);
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();

  const handleLogin = async () => {
    try {
      if (invitation) {
        await loginWithRedirect({
          invitation,
          organization
        });
      }
      else {
        await loginWithRedirect({
          organization: process.env.REACT_APP_AUTH0_ORGANISATION_ID
        })
      }
    } catch (err) {
      console.error(err);
      if (isMountedRef.current) {
        setError(err.message);
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {error &&
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        }
        <Grid item >
          <Button
            fullWidth
            onClick={handleLogin}
            size="large"
            variant="contained"
            startIcon={<LoginIcon fontSize="small" />}
          >
            {invitation !== null && invitation !== undefined ? t('Sign up') : t('Sign in')}
          </Button>
        </Grid>

      </Grid>
    </>
  );
}

export default LoginAuth0;
